import '../../scss/Popular.scss'
import '../../scss/CategoryBig.scss'
import OperationSystem from '../../enums/os'

type BalancePropsType = {
    jwtToken: string
    bundle_id: string | null
    os: OperationSystem | null
    app_version: string | null
}

const Balance = (props: BalancePropsType) => {

    let linkUrl = checkOsVersionForNewShopURL(props.os, props.app_version) ? `dgvg://shop?promo=1&origin=help` : `https://${window.location.hostname.split('.')[0] === 'qa' ? 'qa.' : ''}drugvokrug.ru/shop?${props.bundle_id ? `bundle_id=${props.bundle_id}&` : ''}origin=help&jwt=${props.jwtToken}`;

    let dvLink = `dgvg://dv_browse?url=${encodeURIComponent(linkUrl)}`;

    function checkOsVersionForNewShopURL(os: OperationSystem | null, app_version: string | null): boolean {
        const pattern = /([0-9]+)\.([0-9]+)\.([0-9]+)/gm;
        const substring = app_version?.match(pattern);
        if (substring) {
            const versionDigits = substring[0].split('.');
            let majorVersion, minorVersion, patch;
            if (versionDigits?.length === 3) {
                majorVersion = +versionDigits[0];
                minorVersion = +versionDigits[1];
                patch = +versionDigits[2];
                switch (os) {
                    case 2:
                        return majorVersion > 3 || (majorVersion === 3 && minorVersion > 18) || (majorVersion === 3 && minorVersion === 18 && patch >= 4)
                }
            }
        }
        return false
    }

    return (
        <>
            <div className="zendesk-text">В случае возникновения сложностей с покупкой, вы можете воспользоваться ссылкой на персональный магазин:</div>
            <p>
                <a href={props.os === 1 ? dvLink : linkUrl} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="useful-buttons-item">
                        <div className="useful-buttons-item-text">Открыть магазин</div>
                    </div>
                </a>
            </p>
            <p className="zendesk-text">В открывшемся меню выберите необходимое количество друглей или алмазов, после чего оплатите покупку удобным способом. В случае возникновение трудностей, свяжитесь, пожалуйста, с поддержкой через форму ниже.</p>
        </>
    )
}

export default Balance